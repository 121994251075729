.pagination {
    > li {
        a, span {
            border: none;
            color: #005eac;
            font-size: 14px;
        }
        
        .first,
        .previous {
          > a,
          > span {
            margin-right: 2px;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            margin-right: 30px;  
          }
        }
        .next {
          > a,
          > span {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            margin-left: 30px;  
          }
        }
    }
    
    > li > a,
    > li > span {
        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
    
    > .active > a,
    > .active > span {
    &,
    &:hover,
    &:focus {
            color: #fff;
            background-color: #ed1b2f;
            cursor: default;
            border-radius: 50%;
            text-decoration: none;
            margin: 0 3px;
        }
    }
    
    .first,
    .last,
    .previous,
    .next {
        a{
            border-radius: 50% !important;
            border: none;
            background: #f0f2f5;
            position: relative;
            top: 4px;
            padding: 1px 8px 4px;
            margin: 0 2px;


            span {
               font-size: 9px; 
                color: #6a6b6c;
            }
            &:hover {
                background: #005eac;

                span {
                  color: #fff;  
                }        
            }
        }
    }
    
    .first {
        a {
           margin-right: 15px; 
        } 
        span {
            position: relative;
            right: 1px;
        }
    }
    .last {
        a {
           margin-left: 15px; 
        }  
        span {
            position: relative;
            right: -1px;
        }
    }
}

@media all and (max-width: 650px) {
    
    .pagination {
        > li {
            a, span {
                    font-size: 16px;
                }
        }
        
        .first,
        .last,
        .previous,
        .next  {
            a {
                padding: 2px 10px 5px;
                span {
                   font-size: 10px; 
                } 
            }   
        }
    }
}
