.breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 10px;
    
    li {
        position: relative;
        font-size: 14px;
        margin-right: 28px;
    }
}

.pager li>a {
    border: none;
    background-color: transparent;
    padding: 0;
    
    &:focus, &:hover {
        background-color: transparent;
        text-decoration: underline;
    }
}

.breadcrumb>li+li:before {
    content: '';
    position: absolute;
    padding: 0;
    left: -16px;
    top: 5px;
    border: 4px solid transparent;
    border-left: 4px solid #ccc;
}