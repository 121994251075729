/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";
@import "theme/mibs_breadcrumb.less";
@import "theme/mibs_pagination.less";
@import "theme/mibs_buttons.less";
@import "theme/mibs_forms.less";

a:hover, a:active, a:focus {
    text-decoration: none;
}

.equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.border-box {
    box-sizing: border-box;
}

.modal.fade .modal-dialog {
    transform: none;
}

.modal-content {
    border: none;
    border-radius: 0;
}

.modal.in .modal-dialog {
    transform: none;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: inherit;
        margin: inherit;
    }
    
    .modal-content {
        box-shadow: none; 
    }
}

// The dropdown menu (ul)
.dropdown-menu {
  padding: 20px 0 24px;
  border: none;
  border-radius: inherit;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  .box-shadow(0 2px 24px rgba(108, 108, 108, 0.71));

  // Links within the dropdown menu
  > li > a {
    font-size: 16px;
    padding: 7px 46px 7px 22px;
    line-height: 16px;
  }

  > li > a:hover {
     background-color: transparent;
     color: #005eac;
  }
}

.carousel-control {
  top: 10px;
  left: inherit;
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 42px;
  color: #000;
  text-shadow: none;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1); // Fix IE9 click-thru bug
  // We can't have this transition here because WebKit cancels the carousel
  // animation if you trip this while in the middle of another animation.

  // Set gradients for backgrounds
  &.left {
    right: 54px;
      background-image: none;
  }
  &.right {  
    right: 10px;
      background-image: none;
  }

  // Hover/focus state
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
    .opacity(1);
    color: #000;
  }
    
    &:hover {
        color: #fff;
        background-color: #005eac;
    }
    
  .glyphicon-menu-left,
  .glyphicon-menu-right {
      z-index: 4;
    }
}

.carousel-indicators {
  display: none;
  bottom: 15px;
  z-index: 1;

  li {
    width:  7px;
    height: 7px;
    margin: 1px 10px;
    border-radius: 8px;
    border: 1px solid #f0f2f5;
    cursor: pointer;

    // IE8-9 hack for event handling
    //
    // Internet Explorer 8-9 does not support clicks on elements without a set
    // `background-color`. We cannot use `filter` since that's not viewed as a
    // background color by the browser. Thus, a hack is needed.
    // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Internet_Explorer
    //
    // For IE8, we set solid black as it doesn't support `rgba()`. For IE9, we
    // set alpha transparency for the best results possible.
    background-color: #fff \9; // IE8
    background-color: rgba(255,255,255,1); // IE9
  }
  .active {
    margin: 0 10px;
    width:  9px;
    height: 9px;
    background-color: @carousel-indicator-active-bg;
  }
}

@media screen and (max-width: 600px) {
    .carousel-indicators {
        display: block;
  }
}

.nav-tabs {
    border-bottom: 1px solid #dee1e5;
    padding: 35px 0 0;
    right: 0 !important;
    
    > li {
        line-height: 30px;
        
        &.active {
            border-bottom: 2px solid #005eac;
        }
        
        > a {
            border: none;
            color: #414d58;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 700;
            padding: 0 12px !important;
            
            &:hover {
                background: none !important; 
                color: #414d58 !important; 
            }
        }
        
        &.active > a {
          &,
          &:hover,
          &:focus {
            background: none;  
            border: none;
          }
        }
    }
    
    @media (max-width: 600px) {
        > li {
            width: 45%;
            line-height: 35px;
            
            > a {
                font-size: 10px;
                vertical-align: top;

            }
        
        }
    }
}

//
// Checkboxes
// --------------------------------------------------

.checkbox {
  padding-left: 20px;
  padding-right: 15px;
  margin-bottom: 20px;
}
.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 15px;
    
  &:hover {
     color: #005eac;   
  }
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
    content: '';
    width: 10px;
    height: 6px;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 2px solid #0461ad;
    border-top: none;
    border-right: none;
    background: transparent;
    transform: rotate(-45deg);
}
.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}
.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="radio"]:disabled {
  cursor: not-allowed;
}
.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}

.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled {
  cursor: not-allowed;
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio.radio-inline {
  margin-top: 0;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #337ab7;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f;
}

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e;
}

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c;
}

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
    content: '';
    width: 8px;
    height: 4px;
    position: absolute;
    top: 3px;
    left: 3px;
    border: 2px solid #0461ad;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
}
input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff;
}
input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff;
}



//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  
  background-color: darken(@dropdown-link-hover-bg, 5%);
  color: #fff;  
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
  background-color: darken(@dropdown-link-active-bg, 5%);
}


//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
  #gradient > .vertical(@start-color: lighten(@navbar-default-bg, 10%); @end-color: @navbar-default-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: @navbar-border-radius;
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: darken(@navbar-default-link-active-bg, 5%); @end-color: darken(@navbar-default-link-active-bg, 2%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.075));
  }
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255,255,255,.25);
}

// Inverted navbar
.navbar-inverse {
  #gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: @navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: @navbar-inverse-link-active-bg; @end-color: lighten(@navbar-inverse-link-active-bg, 2.5%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: @grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@alert-info-bg); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }


//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  #gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   { .panel-heading-styles(@panel-default-heading-bg); }
.panel-primary > .panel-heading   { .panel-heading-styles(@panel-primary-heading-bg); }
.panel-success > .panel-heading   { .panel-heading-styles(@panel-success-heading-bg); }
.panel-info > .panel-heading      { .panel-heading-styles(@panel-info-heading-bg); }
.panel-warning > .panel-heading   { .panel-heading-styles(@panel-warning-heading-bg); }
.panel-danger > .panel-heading    { .panel-heading-styles(@panel-danger-heading-bg); }


//
// Wells
// --------------------------------------------------

.well {
  #gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
  border-color: darken(@well-bg, 10%);
  @shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  .box-shadow(@shadow);
}

//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  padding-top:    @jumbotron-padding;
  padding-bottom: @jumbotron-padding;
  margin-bottom: @jumbotron-padding;
  color: @jumbotron-color;
  background-color: @jumbotron-bg;

  h1,
  .h1 {
    color: @jumbotron-heading-color;
  }

  p {
    margin-bottom: (@jumbotron-padding / 2);
    font-size: @jumbotron-font-size;
    font-weight: 200;
  }

  > hr {
    border-top-color: darken(@jumbotron-bg, 10%);
  }

  .container &,
  .container-fluid & {
    border-radius: @border-radius-large; // Only round corners at higher resolutions if contained in a container
    padding-left:  (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: @screen-sm-min) {
    padding-top:    (@jumbotron-padding * 1.6);
    padding-bottom: (@jumbotron-padding * 1.6);

    .container &,
    .container-fluid & {
      padding-left:  (@jumbotron-padding * 2);
      padding-right: (@jumbotron-padding * 2);
    }

    h1,
    .h1 {
      font-size: @jumbotron-heading-font-size;
    }
  }
}
